<template>
  <section class="section section-services" id="services" v-bind="initData">
    <div v-if="info">
      <div class="section-services-main">
        <div class="section-services-wrap">
          <div class="section-services-title">
            <h2>{{ info.title }}</h2>
          </div>
          <div class="section-services-text">
            <p>{{ info.text }}</p>
          </div>
          <div class="section-services-icons" v-if="info.offers">
            <home-services-icon v-for="(icon, idx) in info.offers" :key="idx" :video="icon.videoLink" :icon="icon.iconLink" :text="icon.title"> </home-services-icon>
          </div>
          <div class="section-services-sitesBtnsContainer">
            <sites-section :title="info.sitesTitle" :description="info.sitesDescription" :playText="info.sitesPlayName" :sites="sitesData" v-if="!isLessThan768" />
            <div class="section-services-options">
              <a href="https://api.whatsapp.com/send?phone=573009135094&text=%C2%A1Hola!%20Models%201A,%20deseo%20m%C3%A1s%20informaci%C3%B3n" class="primary-button button" target="_blank">
                {{ talkToUs }}
              </a>
              <signup-button :title="info.btnText"></signup-button>
            </div>
          </div>
        </div>
      </div>
      <div class="section-services-sitesMobile" v-if="isLessThan768">
        <sites-section :title="info.sitesTitle" :description="info.sitesDescription" :playText="info.sitesPlayName" :sites="sitesData" />
      </div>
    </div>
  </section>
</template>

<script>
  import store from "../../store/index.js";
  import HomeServicesIcon from "./HomeServicesIcon.vue";
  import SignupButton from "./SignupButton.vue";
  import router from "../../router";
  import SitesSection from "./SitesSection.vue";
  import { mapGetters } from "vuex";

  export default {
    components: {
      HomeServicesIcon,
      SignupButton,
      SitesSection,
    },
    props: {
      to: Object,
    },
    data: function () {
      return {
        info: null,
        talkToUs: null,
        sitesData: [
          {
            videochunks: "https://cdn.models1a.com/videosChunks/Medellin/Medellin.m3u8",
            videoOriginal: "https://cdn.models1a.com/videosChunks/Medellin/Medellin.mp4",
            imagePoster: "https://new-web-site-bucket.s3.amazonaws.com/sedes/img/medellin.webp",
            city: "Medellín",
          },
          {
            videochunks: "https://cdn.models1a.com/videosChunks/pereira/pereira.m3u8",
            videoOriginal: "https://cdn.models1a.com/videosChunks/pereira/pereira.mp4",
            imagePoster: "https://new-web-site-bucket.s3.amazonaws.com/sedes/img/pereira.webp",
            city: "Pereira",
          },
          {
            videochunks: "https://cdn.models1a.com/videosChunks/cucuta/cucuta.m3u8",
            videoOriginal: "https://cdn.models1a.com/videosChunks/cucuta/cucuta.mp4",
            imagePoster: "https://new-web-site-bucket.s3.amazonaws.com/sedes/img/cucuta.webp",
            city: "Cúcuta",
          },
        ],
      };
    },
    methods: {
      getLanguage: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.info = dataLang.weOffer;
          this.talkToUs = dataLang.training.talkToUs;
          if (this.to.hash === "#services") {
            await router.push({ hash: "" });
            router.push({ hash: this.to.hash });
            await new Promise((resolve) => setTimeout(resolve, 500));
            const testimonialsElement = document.getElementById("services");
            if (testimonialsElement) {
              const offsetTop = testimonialsElement.offsetTop;
              const windowHeight = window.innerHeight;
              const elementHeight = testimonialsElement.offsetHeight;
              const scrollToY = offsetTop - (windowHeight - elementHeight) / 2;
              window.scrollTo({ top: scrollToY, behavior: "smooth" });
            }
          }
        }
      },
    },
    computed: {
      ...mapGetters(["screen"]),
      initData: async function () {
        this.getLanguage();
      },
      isLessThan768() {
        return this.screen.width <= 768;
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  @keyframes fadeInSize {
    0% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
  .section-services {
    text-align: center;
    position: relative;
    margin-top: 30px;
    &-main {
      max-width: 1300px;
      margin: 0 auto;
      padding: 0 $mpadding;
    }
    &-title > h2 {
      font-family: $sec_font;
      color: $primary_color;
      font-size: 42px;
      font-weight: normal;
      margin-bottom: -20px;
    }
    &-wrap {
      box-shadow: 0 2px 5px #d8d8d8;
      padding: $mpadding/2 $space-20;
      border-radius: $mradius;
      padding-top: 50px;
      &::after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        height: 28%;
      }
    }
    &-sitesBtnsContainer {
      @include Flex(column-reverse, space-between, center);
    }
    &-sitesMobile {
      width: 100%;
      height: 100%;
    }
    &-text {
      padding: $mpadding * 3 0;
      p {
        max-width: 690px;
        line-height: 1.625;
        margin: 0 auto;
        color: #5b5957;
      }
    }
    &-icons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      width: 100%;
      &-icon {
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
        position: relative;
        border-radius: 10px 10px 10px;
        background-color: #fff;
        padding: 10px 0;
        &:hover {
          .figure img {
            transform: scale(1.2);
          }
        }
        .figure {
          position: relative;
          z-index: 1;
          img {
            width: 62px;
            transition: transform 0.2s ease-in;
            .star {
              fill: none;
            }
          }
        }
        .text {
          font-family: $sec_font;
          border-radius: 9px;
          padding: $mpadding/2;
          height: 40px;
          display: flex;
          text-align: center;
          align-items: center;
          position: relative;
          z-index: 0;
          h2 {
            margin: 0 auto;
            font-size: 90%;
            padding-top: 10px;
            font-weight: 200;
          }
        }
        &:hover {
          background-color: $primary_color;
          -webkit-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          .text {
            color: $white;
          }
          .text::before {
            width: 100%;
            left: 0;
          }
        }
      }
    }
    &-options {
      width: 100%;
      padding: $mpadding 0;
      .button {
        border-radius: 20px;
        display: block;
        position: relative;
        padding: 10px;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        background: $primary_color;
        color: #fff;
        border: 2px solid $primary_color;
        z-index: 1;
        overflow: hidden;
        margin: 0 auto;
        width: 60%;
        font-size: 13px;
        font-weight: 600;
        &:last-of-type {
          margin-top: 10px;
        }
      }
    }
    @media (min-width: $tablet_width) {
      &-icon {
        box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.1);
        padding: 0;
      }
      &-title > h2 {
        font-size: 30px;
        margin-bottom: -$mpadding * 2;
        padding-top: 45px;
      }
      &-wrap {
        box-shadow: none;
        &::after {
          top: 5%;
          left: 0;
          right: 0;
          height: 26%;
          background-color: #f6f6f6;
        }
      }

      &-icons {
        padding: 10px;
        grid-template-columns: repeat(2, 250px);
        grid-gap: 20px;
        justify-content: center;
        margin-top: 20px;
        &-icon {
          width: 100%;
          .figure {
            img {
              font-size: 68px;
            }
          }
          .wrap {
            padding: $mpadding;
          }
          .text {
            h2 {
              font-size: 100%;
            }
          }
          &:nth-child(1) {
            display: none;
          }
        }
      }
      &-options {
        padding: $mpadding * 2 0;
        .button {
          display: inline-block;
          width: 273px;
          margin: 0 10px;
        }
      }
    }
    @media (min-width: $desktop_width) {
      &-icons {
        grid-template-columns: repeat(3, 230px);
      }
      &-sitesBtnsContainer {
        flex-direction: column;
      }
    }
    @media (min-width: $tv_width) {
      &-wrap {
        &::after {
          top: 70px;
        }
      }
      &-icon {
        box-shadow: 0px 2px 31px rgba(0, 0, 0, 0.1);
      }
      &-icons {
        font-size: 15px;
        grid-template-columns: repeat(5, 230px);
      }
    }
  }
</style>
