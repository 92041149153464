<script>
  import Swiper from "swiper";
  import "swiper/swiper-bundle.css";
  import loadChunks from "../../utils/loadVideoChunks";

  export default {
    props: {
      data: {
        type: Array,
        required: true,
      },
      swiperSettings: {
        type: Object,
      },
    },
    data() {
      return {
        swiper: null,
      };
    },
    computed: {
      canDoHover() {
        return this.$store.state.screen.width > 1300;
      },
    },
    methods: {
      navigateToVideo(video) {
        const query = {
          v: this.urlEncodeB64(video),
          backto: "/#sites",
          size: true,
        };

        this.$router.push({
          path: "watch",
          query,
        });
      },
      urlEncodeB64(url) {
        return btoa(url);
      },
      setVideoPlayCurrent(videoContainer) {
        const container = videoContainer?.target;

        if (!container) {
          return;
        }

        const videoElement = container.querySelector(".sitesCarouselDesktop__video");
        const method = videoContainer.type === "mouseenter" ? "play" : "pause";

        videoElement[method]();
      },
    },
    mounted() {
      this.swiper = new Swiper(".swiper-container", this.swiperSettings);
      this.$nextTick(() => {
        this.data.forEach((item, index) => {
          const videoElement = this.$refs[`video${index}`][0];
          loadChunks(videoElement, item.videochunks, item.videoOriginal);
        });
      });
    },
  };
</script>

<template>
  <div class="sitesCarouselDesktop swiper-container">
    <div class="swiper-wrapper">
      <div
        :class="`swiper-slide ${canDoHover ? 'swiper-slide-touch' : ''}`"
        v-for="(item, i) in data"
        :key="i"
        :ref="`videoContainer${i}`"
        @mouseenter="setVideoPlayCurrent"
        @mouseleave="setVideoPlayCurrent"
        @click="navigateToVideo(item.videoOriginal)">
        <div class="sitesCarouselDesktop__videoContainer">
          <video class="sitesCarouselDesktop__video" :poster="item.imagePoster" :ref="`video${i}`"></video>
          <div class="sitesCarouselDesktop__imgPoster">
            <img :src="item.imagePoster" alt="site image" class="sitesCarouselDesktop__img" />
            <div class="sitesCarouselDesktop__gradient">
              <div class="sitesCarouselDesktop__playIconContainer">
                <iconic name="play" class="sitesCarouselDesktop__playIcon" />
              </div>
            </div>
          </div>
        </div>
        <div class="sitesCarouselDesktop__cityName">{{ item.city }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .sitesCarouselDesktop {
    overflow: visible;
    &__videoContainer {
      @include Flex();
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }
    &__imgPoster {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px 10px $radius-0 $radius-0;
    }
    &__playIconContainer {
      position: absolute;
      @include Flex();
      width: 30px;
      height: 30px;
      background-color: $primary-color;
      border-radius: 50%;
      bottom: 6px;
      right: 6px;
    }
    &__gradient {
      @include Flex(row, flex-end, center);
      width: 100%;
      height: 40px;
      padding: $space-0 10px 10px $space-0;
      position: absolute;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }
    &__playIcon {
      font-size: 16px;
      margin-left: $space-2;
      color: #fff;
      z-index: 1;
    }
    &__video {
      opacity: 0;
      width: 100%;
      height: 100%;
      min-height: 100%;
      object-fit: cover;
      border-radius: 10px 10px $radius-0 $radius-0;
      &:fullscreen {
        object-fit: contain;
      }
    }
    &__cityName {
      color: $primary-color;
      font-family: $sec_font;
      font-size: 24px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      height: 30px;
      border-radius: $radius-0 $radius-0 10px 10px;
      background-color: #fff;
      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .swiper-container {
      width: 100%;
    }
    .swiper-slide {
      @include Flex(column);
      position: relative;
      height: 340px !important;
      width: 200px !important;
      padding: $space-16;
      cursor: pointer;
      transition: all 0.3s;
      &-touch:hover {
        transform: scale(1.1);
        .sitesCarouselDesktop__video {
          opacity: 1;
          z-index: 1;
        }
        .sitesCarouselDesktop__imgPoster {
          opacity: 0;
        }
      }
    }
    .swiper-wrapper {
      justify-content: center;
    }
    @media (min-width: 1440px) {
      .swiper-slide {
        height: 400px !important;
        width: 230px !important;
      }
      .swiper-wrapper {
        justify-content: flex-end;
        margin-left: 15px;
      }
    }
  }
</style>
