import Hls from "hls.js";
export default function loadChunks(videoElement, videoChunks, videoFallback) {
  if (Hls.isSupported()) {
    const hls = new Hls();
    hls.loadSource(videoChunks);
    hls.attachMedia(videoElement);
    hls.on(Hls.Events.ERROR, (event, data) => {
      console.error("Error en HLS al cargar el m3u8", data);
      videoElement.src = videoFallback;
    });
  } else {
    videoElement.src = videoFallback;
  }
}
