<template>
  <div class="video-section modal" id="watch" v-bind="onVideo">
    <div class="video animated fadeInDown" :class="size ? 'size' : null">
      <header v-show="!loading">
        <a class="video-close" @click="goBack"><i class="fa fa-times"></i></a>
      </header>
      <div class="video-section-loading" v-show="loading" :class="{ loading: loading }"></div>
      <div v-show="!loading">
        <video @loadeddata="handleMedia" class="homeVideo" width="100%" playsinline :muted="this.$screen.width < 1024 ? true : false" controls autoplay>
          <source :src="urlCloudFront(mediaLink)" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "../../store/index.js";
  import router from "../../router";
  export default {
    data: function () {
      return {
        prevRoute: [],
        mediaLink: this.urlDecodeB64(this.$route.query.v),
        size: this.$route.query.size,
        info: null,
        locale: null,
        loading: true,
      };
    },
    metaInfo: function () {
      const title = this.locale ? "- " + this.locale.watch.description : "";
      return {
        title: `Models1A ${title}`,
      };
    },
    methods: {
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      getLang: async function () {
        if (store.getters["iflanguage"]) {
          this.info = await store.getters["dataLang"];
          this.locale = await store.getters["localeLang"];
        }
      },
      startVideo: function () {
        const mbody = document.querySelector("body");
        mbody.classList.add("onvideo");
      },
      urlDecodeB64: function (url) {
        return atob(url);
      },
      goBack: function () {
        if (this.$route.query.backto) {
          router.push(this.$route.query.backto);
        } else {
          router.go(-1);
        }
      },
      handleMedia() {
        this.loading = false;
      },
    },
    computed: {
      onVideo: async function () {
        this.startVideo();
        await this.getLang();
      },
    },
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.prevRoute = from;
      });
    },
    beforeRouteLeave(to, from, next) {
      const mbody = document.querySelector("body");
      mbody.classList.remove("onvideo");
      next();
    },
    mounted() {
      window.addEventListener("keydown", function (event) {
        if (event.code === "Escape" || event.keyUp === 27) {
          router.push("/");
        }
      });
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .video-section {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.88);
    color: $white;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    .video {
      margin: 90px auto 110px;
      max-width: 90%;
      .video-close {
        cursor: pointer;
      }
      header {
        margin-top: 30px;
        text-align: right;
        font-size: 160%;
        padding: 5px 0;
      }
    }
    .size {
      max-width: 86%;
    }

    &-loading {
      &:before {
        left: calc(50% - -60px);
      }
      width: 100%;
      height: 650px;
      display: flex;
      align-items: center;
      justify-content: start;
      position: relative;
      left: calc(50% - 100px);
    }
    @media (min-width: $tablet_width) {
      .homeVideo {
        max-height: 95vh;
      }
      .video {
        max-width: 50%;
      }
    }
  }
</style>
